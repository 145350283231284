import * as Yup from 'yup'
import { parsePhoneNumber } from 'awesome-phonenumber'

const COUNTRY_CODE = 'US'

Yup.addMethod<Yup.StringSchema>(Yup.string, 'phoneNumber', function validate() {
  return this.test('Phone number format test', 'Invalid phone number', (value) => {
    if (value === null || value === undefined) {
      return true
    }
    return parsePhoneNumber(value, { regionCode: COUNTRY_CODE }).valid
  })
})
