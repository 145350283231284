import { parsePhoneNumber } from 'awesome-phonenumber'

const COUNTRY_CODE = 'US'

export const convertPhoneNumberToE164 = (phone: string | null | undefined) => {
  if (phone === undefined) return undefined

  if (phone === '' || phone === null) {
    return null
  }
  const parsedPhone = parsePhoneNumber(phone, { regionCode: COUNTRY_CODE })
  return parsedPhone.valid ? parsedPhone.number.e164 : undefined
}

export const convertPhoneNumberToNational = (phone: string | null | undefined) => {
  if (!phone) {
    return undefined
  }
  const parsedPhone = parsePhoneNumber(phone, { regionCode: COUNTRY_CODE })
  return parsedPhone.valid ? parsedPhone.number.national : undefined
}
